<script setup>
import Overlay from "@/assets/js/src/components/Overlay.vue"
import HomeSplash from "@/assets/js/src/modules/home/_components/HomeSplash.vue"
import HomeFooter from "@/assets/js/src/modules/home/_components/HomeFooter.vue"
import BsVImg from "@/assets/js/src/components/BsVImg"
import {useLandingApi,} from "@/assets/js/src/modules/chat/_composables/useLandingApi"
import {computed, ref,} from "vue"
import {getActivePinia, storeToRefs,} from "pinia"
import {useAppUiStore,} from "@/assets/js/src/pinia/appUi"
import {useLangStore,} from "@/assets/js/src/modules/lang/_pinia/lang"
import {useHeadInfo,} from "@/assets/js/src/modules/chat/_composables/useHeadInfo"
import {useTranslation,} from "@/assets/js/src/util/composables"
import ChatFeatures from "@/assets/js/src/modules/chat/_components/landing/ChatFeatures.vue"
import ChatTestimonials from "@/assets/js/src/modules/chat/_components/landing/ChatTestimonials.vue"
import ChatFaq from "@/assets/js/src/modules/chat/_components/landing/ChatFaq.vue"
import TryButton from "@/assets/js/src/modules/chat/_components/landing/TryButton.vue"
import {copyrightAutolink,} from '@/assets/js/src/util/imgTools'
import NikoSvg from "@/assets/js/src/modules/chat/_components/NikoSvg.vue"
import ChatVideo from "@/assets/js/src/modules/chat/_components/landing/ChatVideo.vue"

const {messagesLoaded,allAsyncLoaded,testimonials,} = useLandingApi()
const activePinia = getActivePinia()
const {countRouteChange,} = storeToRefs(useAppUiStore(activePinia))
const {locale,isDeLocale,} = storeToRefs(useLangStore(activePinia))
const imageLoaded = ref(false)
const {t,} = useTranslation()
const backImg = import.meta.glob('@/assets/images/nicodemus-ai/background.jpg', {eager: true,})['/assets/images/nicodemus-ai/background.jpg'].default
useHeadInfo()

const imageCopyright = computed(() => {
    return copyrightAutolink({copyright: 'Semar Design/stock.adobe.com',})
})

</script>

<template>
    <overlay
        full-width
        class-name="chat-landing overflow-x-hidden"
        :ready-to-scroll="allAsyncLoaded"
    >
        <home-splash :loading="!imageLoaded && countRouteChange <= 1"></home-splash>
        <bs-v-img
            v-if="messagesLoaded"
            :src="backImg"
            position="top center"
            cover
            :class="['secondary--text','hidden-print-only','main-pic']"
            @load="imageLoaded = true"
        >
            <template #placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                    <v-progress-circular
                        indeterminate
                        :class="['secondary--text']"
                    ></v-progress-circular>
                </v-row>
            </template>
            <div class="chat-landing__avatar">
                <niko-svg size="256px"></niko-svg>
            </div>
            <div class="chat-landing__description">
                <h1 v-html="t('async.menu.nicodemus-ai')"></h1>
                <p class="mb-4">
                    {{ t('async.nicodemus-ai.landing.subtitle') }}
                </p>
                <div class="mx-n2 mt-">
                    <try-button />
                </div>
            </div>
            <footer
                class="chat-landing__img-copy"
                v-html="imageCopyright"
            ></footer>
        </bs-v-img>
        <v-skeleton-loader
            v-else
            type="image"
            class="v-skeleton-loader__custom-image"
            tile
        ></v-skeleton-loader>
        <chat-features v-if="messagesLoaded"></chat-features>
        <chat-video v-if="isDeLocale && messagesLoaded"></chat-video>
        <chat-testimonials v-if="testimonials.length"></chat-testimonials>
        <chat-faq v-if="messagesLoaded"></chat-faq>
        <home-footer
            :locale="locale"
            :heading="messagesLoaded ? t('async.home.footer') : ''"
        ></home-footer>
    </overlay>
</template>

<style lang="scss">
@use "@/assets/js/src/style/elements/btnBack";
@use "@/assets/js/src/style/elements/imgCopy";

.bs-app {
    .chat-landing {
        z-index: auto;
        padding-top: 0 !important;
        padding-bottom: 0;

        @include btnBack.btnBack;
        @include imgCopy.imgCopy;

        &__img-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(map-deep-get($bs-color, bs), .4);
        }

        &__avatar {
            position: absolute;
            bottom: 50vh;
            bottom: calc(var(--vh) * 50);
            left: 50%;
            max-width: 256px;
            max-height: 256px;
            transform: translateX(-50%);
        }

        &__description {
            position: absolute;
            bottom: 5vh;
            bottom: calc(var(--vh) * 5);
            left: 50%;
            min-width: 670px;
            max-width: 670px;
            margin: 0 auto;
            padding: 48px;
            background: rgba(map-deep-get($bs-color, bs), .7);
            border-radius: map-deep-get($bs-xl, autocomplete, inputRadius);
            transform: translateX(-50%);

            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                bottom: 10vh;
                bottom: calc(var(--vh) * 10);
                left: auto;
                min-width: auto;
                max-width: none;
                margin: 0 16px;
                padding: 12px;
                transform: none;
            }

            @media (height >= 640px) and (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                bottom: 15vh;
                bottom: calc(var(--vh) * 15);
                padding: 24px;
            }

            h1 {
                color: #fff;
                font-weight: 200;
                font-size: 48px;
                line-height: 57px;

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    font-size: 30px;
                    line-height: 35px;
                }

                sup {
                    display: inline-block;
                    margin-left: .5rem;
                    font-size: 28px;
                    transform: translateY(-4px);

                    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                        margin-left: .25rem;
                        font-size: 20px;
                        transform: translateY(0);
                    }
                }
            }

            p {
                margin: 32px 0 0;
                color: #fff;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                hyphens: auto;

                &.subtitle {
                    margin: 16px 0 0;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 24px;
                }
            }
        }

        .main-pic {
            &.v-img, &.v-progress-linear {
                height: 100vh;
                height: calc(var(--vh) * 100);

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    min-width: 100vw;
                    margin: 0 -16px;
                }
            }
        }

        .bs-home__footer__inner {
            margin-top: 3rem;
        }

        .chat-examples, .chat-faq, .chat-features, .bs-home__footer, .chat-video, .chat-testimonials {
            @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                width: 100vw;
                margin: 0 -16px;
            }
        }
    }
}
</style>
